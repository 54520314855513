<template>
  <div>
    <!-- 引入的返回头组件 -->
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBlack" />
    <div class="desk-right">
      <div class="add-food">
        <van-form @submit="onSubmit" :submit-on-enter="false">
          <!-- <van-field
            v-model="card_num"
            type="text"
            name="card_num"
            label="会员卡号"
            placeholder="自动生成"
            :disabled="true"
          /> -->
          <van-field
            v-model="ic_card_num"
            type="number"
            name="ic_card_num"
            label="会员卡号"
            placeholder="绑定实体会员卡请在光标闪烁时将卡片贴近读卡器"
          />
          <van-field
            v-model="nickname"
            type="text"
            name="nickname"
            label="会员姓名"
            placeholder="请输入会员姓名"
            required
          />
          <van-field
            v-model="mobile"
            type="Number"
            name="mobile"
            label="会员电话"
            placeholder="请输入会员电话"
            required
          />
          <van-field name="radio" label="会员类型" required>
            <template #input>
              <van-radio-group v-model="type" direction="horizontal">
                <van-radio
                  :name="item.id"
                  v-for="item in columns"
                  :key="item.id"
                  >{{ item.mem_type_text }}</van-radio
                >
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="status" label="禁用会员">
            <template #input>
              <van-switch
                v-model="status"
                :active-value="0"
                :inactive-value="1"
              />
            </template>
          </van-field>
          <van-field name="gender" label="会员性别">
            <template #input>
              <van-radio-group v-model="gender" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            readonly
            clickable
            name="birthday"
            :value="birthday"
            label="会员生日"
            placeholder="请选择会员生日"
            @click="showbirthday = true"
          />
          <van-field
            v-model="remark"
            type="text"
            name="remark"
            label="备注"
            placeholder="请输入备注"
          />
          <div style="margin: 16px" class="flex flex-jc">
            <van-button round block type="info" native-type="submit"
              >保 存</van-button
            >
          </div>
          <van-popup v-model="showbirthday" round position="bottom" class="popup-picker-custom">
            <van-datetime-picker
              v-model="currentDate"
              :min-date="minDate"
              type="date"
              @confirm="onConfirm3"
              @cancel="showbirthday = false"
              item-height="8.5rem"
              :formatter="formatter"
            />
          </van-popup>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { datetimePickerFormatter } from '../../utils/util';
import titlebar from "@/views/component/titlebar";
export default {
  name: "addmember",
  components: {
    titlebar
  },
  data() {
    return {
      card_num: "",
      nickname: "",
      mobile: "",
      type: "", //选中的显示
      status: 1,
      gender: "",
      columns: [], // 会员类型
      currentDate: new Date(),
      birthday: "",
      showbirthday: false,
      remark: "",
      minDate: new Date(1900, 0, 1),
      changeDate: new Date(),
      id: "",
      order_id: "",
      formatter: datetimePickerFormatter,
      page_name: '',
      ic_card_num: '',
    };
  },
  async created() {
    this.page_name = this.$route.query.name || '';
    await this.getmembertype();
    this.id = this.$route.query.id || this.$route.params.id;
    this.order_id = this.$route.params.order_id;
    // 没id新增，有id编辑
    if (this.id) {
      console.log("456");
      this.getmember();
    } else {
      console.log("123");
      this.getCardNum();
    }
  },
  mounted() {},
  methods: {
    onConfirm3(time) {
      this.birthday = this.timeFormat(time);
      this.showbirthday = false;
    },
    onSubmit(e) {
      if (!this.nickname || this.nickname == '') {
        this.$toast('会员姓名不能为空')
        return;
      }
      if (!this.mobile || this.mobile == '') {
        this.$toast('会员电话不能为空')
        return;
      }
      if (!this.type || this.type == '') {
        this.$toast('会员类型不能为空')
        return;
      }
      let data = {
        name: this.nickname,
        phone: this.mobile,
        sex: this.gender,
        birth: this.birthday,
        type: this.type,
        remark: this.remark,
        status: this.status,
        card_num: this.card_num,
        ic_card_num: this.ic_card_num || '',
      }
      if (this.id) {
        data.id = this.id;
        this.$api.dt_editMember(data).then((res) => {
          if (res.code == 1) {
            this.$toast("修改成功！");
            this.goBlack();
          } else if (res.code = 1) {
            this.$toast(res.msg);
          }
        });
      } else {
        this.$api.dt_addMember(data).then((res) => {
          if (res.code == 1) {
            this.$toast("新增成功！");
            this.goBlack(res.data);
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
    timeFormat(time) {
      // 时间格式化
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = time.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    },
    // 获取会员卡号
    getCardNum() {
      this.$api.dt_getCardNum().then((res) => {
        if (res.code == 1) {
          this.card_num = res.data.card_num;
        }
      });
    },
    //获取会员类型
    getmembertype() {
      return new Promise((resolve, reject) => {
        this.$api.getMemberTypeList().then((res) => {
          if (res.code == 1) {
            let list = res.data.data || [];
            list.forEach((item) => {
              let rate = '';
              if (item.rate % 10 > 0) {
                rate = item.rate / 10 + "折";
              } else if (item.rate / 100 < 1 && item.rate % 10 == 0) {
                rate = parseInt(item.rate / 10) + "折";
              } else {
                rate = "无折扣";
              }
              item['mem_type_text'] = item.type_name + "--" + rate;
              item.id = parseInt(item.id);
            });
            this.columns = list;
            resolve();
          } else {
            this.$toast(res.msg);
            reject();
          }
        });
      })
    },
    // 根据id查询会员详情
    getmember() {
      this.$api.dt_getMemberList({ id: this.id }).then((res) => {
        if (res.code == 1) {
          const info = res.data.data.list[0]
          this.status = info.status ? Number(info.status) : 1;
          this.nickname = info.name || '';
          this.mobile = info.phone || '';
          this.type = info.type ? Number(info.type) : '';
          this.gender = info.sex || '';
          this.birthday = info.birth || '';
          this.card_num = info.card_num || '';
          this.ic_card_num = info.ic_card_num || '';
        } else {
          this.$toast(res.msg);
        }
      });
    },
    goBlack(data) {
      if (this.$route.params.type == "orderList") {
        this.$router.push({
          name: "orderList",
          params: { order_id: this.order_id },
        });
      } else if (this.$route.params.type == 'memberlist') {
        this.$router.replace({
          name: 'vipManagement',
          params: {
            page: this.$route.query.page,
            content: this.$route.query.content,
            active: this.$route.query.active,
          },
        })
      } else if (this.$route.params.type == "index" || this.$route.params.type == 'settlement') {
        let obj = this.columns.find(e => e.id == this.type);
        data['rate'] = obj.rate;
        data['member_type'] = obj.type_name;
        this.$store.commit('setVipInfo', data);
        this.$router.replace({
          name: this.$route.params.type,
          query: {
            name: this.$route.params.type,
          }
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}
.desk-right {
  height: calc(100vh - 19.7rem);
  margin-top: 1.5rem;

  .add-food {
    //border: 1px solid red;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .van-cell.van-field {
      font-size: 2.6rem;
      padding: 2rem 3rem;

      /deep/.van-cell__title span {
        line-height: 6rem;
      }

      /deep/.van-field__control {
        height: 6rem;

        .van-radio__icon {
          font-size: 3rem;
        }

        .van-radio__icon--checked {
          font-size: 3rem;

          .van-icon {
            background-color: #1588f5;
            border-color: #1588f5;
          }
        }
      }
    }

    .van-button--normal {
      font-size: 2.8rem;
      background-color: #1588f5;
      border-color: #1588f5;
    }
  }
}
.van-button--info {
  width: 44.6rem;
  height: 7rem;
  background: #1588f5;
  border-radius: 0.8rem;
  border: 0;
}
/deep/.van-cell--required::before {
  position: absolute;
  left: 1.4rem;
  color: #ee0a24;
  font-size: 2.6rem;
  content: "*";
  top: 36%;
}
</style>